<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title>{{ `${$t('menu.configuration')} / ${$t('landing.catalogs')}` }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <Catalogs />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiInformation,
} from '@mdi/js'
import Catalogs from '../utils/Catalogs.vue'

export default {
  components: {
    Catalogs,
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      avatar: null,
      previewImage: null,
      isotipo: null,
      previewIsotipo: null,
      favicon: null,
      previewFavicon: null,
      cunno: null,
      previewCunno: null,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiInformation,
      },
      model: {},
      id_model: -1,
      permisos: [],
      loading: false,
      tipos_fiscal: [],
      tipos_cuentas_bancarias: [],
      monedas: [],
      countries: [],
      paisUser: {},
      pos: 0,
      payMents: [],
      swiftRules: [
        // v => !!v || 'Campo requerido',

        // v => v.length <= 8 || 'Swift no válido',
        v => /[0-9a-zA-Z]{8}/.test(v) || 'Swift no válido',
      ],
    }
  },
  computed: {
    ...mapState({
      emailsReserve: state => state.app.emailsReserve,
      ctasBancarias: state => state.app.ctasBancarias,
      dataBancaria: state => state.app.dataBancaria,
      posCotizador: state => state.app.posCotizador,
    }),
  },
  created() {
    this.pos = this.posCotizador

    this.loadPermisos()
  },
  methods: {
    ...mapMutations([
      'addEmailAdminReserve',
      'updateEmailAdminReserve',
      'addCtasBancarias',
      'updateCtasBancarias',
      'setPosCotizador',
      'updateDataBancaria',
    ]),
    loadPermisos() {
      this.axios
        .get('permiso?per_page=1000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(per => {
          this.permisos = per.data.data
        })
    },
  },
}
</script>
<style>
/**PARA OCULTAR LA FECHA DE HOY EN EL COMPONENTE DE HORA */
.vc-date-time .vc-date .vc-weekday,
.vc-date-time .vc-date .vc-month,
.vc-date-time .vc-date .vc-day,
.vc-date-time .vc-date .vc-year {
  display: none;
}
</style>
