<template>
  <v-card>
    <v-card-text>
      <v-img
        height="100px"
        contain
        :src="urlS3+ catalog.logo"
      ></v-img>
    </v-card-text>
    <v-card-text class="d-flex mt-5">
      <!--<v-avatar
        size="54"
        rounded
        color="primary"
        class="v-avatar-light-bg primary--text mt-1"
      >
        <div>
          <p class="font-weight-medium mb-0">
            Fev
          </p>
          <p class="text-xl primary--text font-weight-bold mb-0">
            2
          </p>
        </div>
      </v-avatar>-->

      <div class="ms-3">
        <p class="text-base font-weight-semibold text--primary mb-0">
          {{ getTitle(catalog) }}
        </p>
        <span class="text-xs">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
              >{{ getDescripcion(catalog) | truncate(85) }}</span>
            </template>
            <span>{{ getDescripcion(catalog) }}</span>
          </v-tooltip>
        </span>
      </div>
    </v-card-text>
    <v-card-text>
      <v-divider></v-divider>
    </v-card-text>
    <v-card-text class="text-center">
      <v-row>
        <v-col cols="6">
          <div>
            <v-icon size="30">
              mdi-download-outline
            </v-icon>
          </div>
          <span
            v-if="catalog.cant_download > 0"
            class="text-no-wrap text--primary"
          >
            {{ catalog.cant_download | number('0a') }}
          </span>

          <br />
          <div class="mt-4">
            <v-icon size="30">
              mdi-weight
            </v-icon>
          </div>
          <span class="text-no-wrap text--primary">
            {{ catalog.size_pdf }}
          </span>
        </v-col>
        <v-col cols="3">
          <div>
            <span v-html="qr"></span>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text>
      <v-divider></v-divider>
    </v-card-text>
    <v-card-text class="text-center">
      <v-row>
        <v-col cols="8">
          <v-btn
            color="primary"
            block
            outlined
            @click="$emit('editItem', catalog.id)"
          >
            <span>{{ $t('btn.edit') }}</span>
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="4">
          <v-switch
            v-model="catalog.active"
            hide-details
            class="mt-1"
            :label="$t('lbl.active')"
            @change="showEditActive(catalog)"
          ></v-switch>
        </v-col>
      </v-row>
    </v-card-text>
    <!--<v-card-text>
      <v-divider></v-divider>
    </v-card-text>
    <v-card-text>
      <div
        v-for="(data,index) in developerMeetup"
        :key="data.icon"
        :class="`d-flex align-center ${index > 0 ? 'mt-2':''}`"
      >
        <v-icon
          size="20"
          class="mt-n4"
        >
          {{ data.icon }}
        </v-icon>
        <div class="ms-2">
          <p class="text--primary mb-0">
            {{ data.title }}
          </p>
          <span class="text-xs">{{ data.subtitle }}</span>
        </div>
      </div>
    </v-card-text>-->
  </v-card>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
  mdiClockOutline,
  mdiMapMarkerOutline,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    catalog: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      urlS3: process.env.VUE_APP_API_URL_S3_HOTEL,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
        mdiClockOutline,
        mdiMapMarkerOutline,
      },
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),
      qr: null,
    }
  },
  computed: {
    ...mapState({
      emailsReserve: state => state.app.emailsReserve,
    }),
  },
  created() {
    this.getQR()
  },
  methods: {
    ...mapMutations(['deleteEmailAdminReserve']),
    getTitle(item) {
      if (this.$i18n.locale === 'es') {
        return item.title
      }

      return item[`title_${this.$i18n.locale}`]
    },
    getDescripcion(item) {
      if (this.$i18n.locale === 'es') {
        return item.description
      }

      return item[`description_${this.$i18n.locale}`]
    },
    showEditActive(item) {
      this.axios
        .post('events/update-active-catalog', item, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === true) {
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
    },
    getQR() {
      this.axios
        .post('catalogs/qr-catalogo', { id: this.catalog.id })
        .then(res => {
          this.qr = res.data
        })
    },
  },
}
</script>
<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input input {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .v-text-field__details {
  padding: 0 0 0 0;
}
</style>
