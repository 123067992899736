<template>
  <v-row class="mt-4 ml-2">
    <v-col cols="12">
      <v-row>
        <v-col
          v-if="permisos.includes('events:create')"
          cols="12"
          md="2"
        >
          <v-btn
            color="primary"
            class="mb-4 me-3"
            @click="newItem"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>{{ $t('btn.insert') }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-divider></v-divider>
    </v-col>
    <v-col
      v-for="(catalog, indE) in catalogs"
      :key="indE"
      cols="4"
    >
      <CatalogOne
        :catalog="catalog"
        :pos="indE"
        @editItem="editItem"
      />
    </v-col>

    <SidebarDataCatalog
      v-if="isSidebarDataCatalogActive"
      v-model="isSidebarDataCatalogActive"
      :catalog="itemEdit"
      @getCatalogs="getCatalogs"
    />
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiInformation,
} from '@mdi/js'
import CatalogOne from './CatalogOne.vue'
import SidebarDataCatalog from './SidebarDataCatalog.vue'

export default {
  components: {
    CatalogOne,
    SidebarDataCatalog,
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiInformation,
      },
      model: {},
      permisos: localStorage.getItem('permisos_auth'),
      loading: false,
      catalogs: [],
      isSidebarDataCatalogActive: false,
    }
  },
  computed: {
    ...mapState({
      emailsReserve: state => state.app.emailsReserve,
      ctasBancarias: state => state.app.ctasBancarias,
      dataBancaria: state => state.app.dataBancaria,
      posCotizador: state => state.app.posCotizador,
    }),
  },
  created() {
    this.getCatalogs()
  },
  methods: {
    ...mapMutations([
      'addEmailAdminReserve',
      'updateEmailAdminReserve',
      'addCtasBancarias',
      'updateCtasBancarias',
      'setPosCotizador',
      'updateDataBancaria',
    ]),
    getCatalogs() {
      this.axios
        .post('catalogs/list-catalogos')
        .then(res => {
          this.catalogs = res.data.data
        })
    },
    newItem() {
      this.itemEdit = {
        title: null,
        logo: '',
        pdf: '',
        size_pdf: '',
      }
      this.isSidebarDataCatalogActive = true
    },
    editItem(id) {
      // eslint-disable-next-line prefer-destructuring
      this.itemEdit = this.catalogs.filter(e => e.id === id)[0]
      this.isSidebarDataCatalogActive = true
    },
  },
}
</script>
<style>
/**PARA OCULTAR LA FECHA DE HOY EN EL COMPONENTE DE HORA */
.vc-date-time .vc-date .vc-weekday,
.vc-date-time .vc-date .vc-month,
.vc-date-time .vc-date .vc-day,
.vc-date-time .vc-date .vc-year {
  display: none;
}
</style>
