<template>
  <v-navigation-drawer
    :value="isSidebarDataCatalogActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    width="350"
    app
    @input="val => $emit('update:is-sidebar-data-catalog-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center">
        <h4 class="font-weight-semibold text-base">
          {{ $t('landing.catalog') }}
        </h4>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-sidebar-data-catalog-active', false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text class="pt-5">
        <v-form ref="stepForm">
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="catalog.title"
                :label="`${$t('lbl.title')}`"
                outlined
                dense
                hide-details="auto"
                :rules="textRequiered"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <v-textarea
                v-model="catalog.description"
                :label="`${$t('lbl.description')}`"
                outlined
                dense
                rows="2"
                hide-details="auto"
                :rules="textRequiered"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-file-input
                v-model="logo"
                accept="image/*"
                show-size
                outlined
                dense
                label="Logo"
                hide-details
                @change="setLogo"
              ></v-file-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-file-input
                v-model="pdf"
                accept=".pdf"
                show-size
                outlined
                dense
                :label="$t('landing.catalog')"
                hide-details
                @change="setCatalog"
              ></v-file-input>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-btn
                color="error"
                block
                @click="$emit('update:is-sidebar-data-catalog-active', false)"
              >
                {{ $t('btn.cancele') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-btn
                color="primary"
                outlined
                block
                :loading="loadingBtn"
                @click="save"
              >
                {{ $t('btn.send') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <Loading
      v-if="isLoading"
      :full-page="true"
      :color="$vuetify.theme.themes.light.primary"
    />
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { mdiClose } from '@mdi/js'
// eslint-disable-next-line no-unused-vars, import/no-unresolved
import Loading from '@/views/utils/loading/bars.vue'

export default {
  components: {
    Loading,
  },
  model: {
    prop: 'isSidebarDataCatalogActive',
    event: 'update:is-sidebar-data-catalog-active',
  },
  props: {
    isSidebarDataCatalogActive: {
      type: Boolean,
      required: true,
    },
    // eslint-disable-next-line vue/require-default-prop
    catalog: {
      type: Object,
    },
  },
  data() {
    return {
      rutaAPI: process.env.VUE_APP_API_URL,
      stepForm: null,
      icons: {
        mdiClose,
      },
      textRequiered: [v => !!v || 'Campo requerido'],
      emailRules: [v => !!v || 'Campo requerido', v => /.+@.+/.test(v) || 'E-mail debe ser válido'],
      phoneRules: [v => Number.isInteger(Number(v)) || 'Debe ser solo números'],
      loading: false,
      imgs: [],
      menuFrom: false,
      menuTo: false,
      isLoading: false,
      logo: null,
      pdf: null,
    }
  },
  computed: {
    ...mapState({
      clientCarShop: state => state.app.clientCarShop,
      loadingBtn: state => state.app.loadingBtn,
      carItems: state => state.app.carItems,
      proveedorApis: state => state.app.proveedorApis,
      clientsApis: state => state.app.clientsApis,
    }),
    computedFromDateFormatted() {
      return this.evento.dateFrom ? this.$moment(this.evento.dateFrom).format('D-MMM-YY') : ''
    },
    computedToDateFormatted() {
      return this.evento.dateTo ? this.$moment(this.evento.dateTo).format('D-MMM-YY') : ''
    },
  },
  methods: {
    ...mapMutations(['updateProveedorApi', 'addProveedorApi', 'updateClientApi', 'addClientApi']),
    setLogo() {
      if (this.logo) {
        this.isLoading = true
        const formData = new FormData()

        formData.append('logo', this.logo)

        this.axios
          .post('events/upload-logo-catalog', formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            if (res.data.success === true) {
              this.catalog.logo = res.data.data.data
            }
          })
          .catch(error => console.log(error))
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    setCatalog() {
      if (this.pdf) {
        this.isLoading = true
        const formData = new FormData()

        formData.append('pdf', this.pdf)

        this.axios
          .post('events/upload-pdf-catalog', formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            if (res.data.success === true) {
              this.catalog.pdf = res.data.data.data
              this.catalog.size_pdf = res.data.data.size
            }
          })
          .catch(error => console.log(error))
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    save() {
      if (this.catalog.title) {
        this.axios
          .post('events/update-catalog', this.catalog, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            if (res.data.success === true) {
              this.$toast.success(this.$t('msg.infoSuccess'))
              this.$emit('update:is-sidebar-data-catalog-active', false)
              this.$emit('getCatalogs')
            }
          })
          .catch(error => console.log(error))
          .finally(() => {
            this.isLoading = false
          })
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    gestionarProv() {
      localStorage.setItem('api-id', this.api.id)
      this.$router.push({ name: 'api-keys-proveedors' })
    },
    copyToClipboard(copyText) {
      // const copyText = document.getElementById(id).textContent
      navigator.clipboard.writeText(copyText)

      this.$toast.info(this.$t('msg.copyText', { n: copyText }))
    },
  },
}
</script>
